/*
 * @Author: Liux- 1163777695@qq.com
 * @Date: 2024-05-22 11:08:13
 * @LastEditors: Liux- 1163777695@qq.com
 * @LastEditTime: 2024-05-22 11:10:15
 * @FilePath: /HScommunityWeb/Users/liux-/Desktop/鸿实工作/talent-platform/src/store/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    policyData: [],
    // 记录最后一次点击时间的元素
    lastTime: new Date().getTime(),
  },
  getters: {
  },
  mutations: {
    getPolicyData (state, data) {
      state.policyData = data
    },
    //点击事件调用，刷新最后一次点击时间
    lastTimeUpdata: (state, lastTime) => {
      state.lastTime = lastTime;
    }
  },
  actions: {

  },
  modules: {
  }
})
