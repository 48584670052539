/*
 * @Author: Liux- 1163777695@qq.com
 * @Date: 2024-05-10 11:28:12
 * @LastEditors: Liux- 1163777695@qq.com
 * @LastEditTime: 2024-05-10 13:06:22
 * @FilePath: /pc_mobel/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import './assets/css/global.css'
createApp(App).use(ElementPlus).use(store).use(router).mount('#app')


import * as ElIcon from '@element-plus/icons-vue'

Object.keys(ElIcon).forEach((key) => {
  App.component(key, ElIcon[key])
})