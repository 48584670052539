<!--
 * @Author: Liux- 1163777695@qq.com
 * @Date: 2024-05-10 11:28:12
 * @LastEditors: Liux- 1163777695@qq.com
 * @LastEditTime: 2024-05-22 11:21:06
 * @FilePath: /pc_mobel/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <router-view/>
</template>

<script>
import { timeOut } from '@/utils/time';
export default {
  data(){
    return {}
  },
  mounted() {
    let that = this;
    console.log('❌❌⭕️⭕️🔞🔞🔞🔞进来了',);
    timeOut(that);
		// #ifdef H5
		// /* 实现全局点击事件监听 */
		setTimeout(function() {
			var body_ = document.getElementsByTagName('body')[0];
			body_.addEventListener('click', function() {
				that.$store.commit('lastTimeUpdata', new Date().getTime());
			});
		}, 1000);
		// #endif
  }
}
</script>

<style>

</style>
