/*
 * @Author: Liux- 1163777695@qq.com
 * @Date: 2024-05-22 11:10:46
 * @LastEditors: Liux- 1163777695@qq.com
 * @LastEditTime: 2024-05-22 11:26:13
 * @FilePath: /HScommunityWeb/Users/liux-/Desktop/鸿实工作/talent-platform/src/utils/time.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import store from '../store/index.js';
export const timeOut = (that) => {
    // 上一次点击时间
    let lastTime = null
    // 当前时间
    let currentTime = null
    // 超时时间【可以自己设置】
    // let sys_timeout = 5 * 1000
    let sys_timeout = 2 * 60000
    // 每隔多长时间检查是否超时【可以自己设置】
    let check_time = 1000
    // 计时器【此为功能实现的方法，现在为空】
    let goOut = null
    const isTimeOut = () => {
        // 页面上一次的点击时间
        lastTime = store.state.lastTime
        currentTime = new Date().getTime()
        // 超时了
        if ((currentTime - lastTime) > sys_timeout) {
            return true;
        } else {
            return false;
        }
    }
    const isLoginOut = () => {
        clearInterval(goOut);
        //setInterval方法来确定多长时间检测一次有没有超时
        goOut = setInterval(() => {

            if (isTimeOut()) {
                // 需要转到的页面【这里用你自己的跳转方法和地址】
                that.$router.push({ path: '/' })
                //已经超时跳转到相应界面，不需要计时了
                // clearInterval(goOut)
            }
        }, check_time);
    }
    isLoginOut();
}


